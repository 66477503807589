<template>
<section class="domain_content">
  <!-- <h1 class="center">
        GBWhatsApp Latest Version APK Download Links
    </h1> -->

  <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp download-12" data-exposure="1" @click="download(apk)">
    <img :src="downloadicon" alt="download" class="download-img">
    <div class="download-name">
      Download Now
    </div>
  </div>
  <p class="center">
    If the download doesn't start in a few seconds, please click <span @click="download(apk)" class="inline-download">here</span>
  </p>

  <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp download-12 back-12" data-exposure="1" @click="goback()">
    <img src="@/assets/back.svg" alt="download" class="download-img">
    <div class="download-name">
      Back to Homepage
    </div>
  </div>

  <ModList :modList="modList" :language="language"/>

</section>
</template>

<script>
import '@/css/default.scss';
import {
  mapGetters
} from 'vuex';
import ModList from '@/components/ModList.vue';

export default {
  ...{
    "metaInfo": {
      "title": "Download Page of GB WhatsApp APK For Android",
      "meta": [{
          "name": "description",
          "content": "On this page, users can download the latest GB WhatsApp APK safely without hidden fee or ads. Now, click the button to start downloading."
        }, {
          "name": "title",
          "content": "Download Page of GB WhatsApp APK For Android"
        }, {
          "name": "keywords",
          "content": "gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download"
        },
        {
          "property": "og:title",
          "content": "Download Page of GB WhatsApp APK For Android"
        }, {
          "property": "og:description",
          "content": "On this page, users can download the latest GB WhatsApp APK safely without hidden fee or ads. Now, click the button to start downloading."
        }
      ],
      "link": [{
        "rel": "canonical",
        "href": "https://waproapk.com/download-gbwhatsapp/"
      }]
    }
  },
  components: {
    ModList
  },
  data() {
    return {
      from: null,
      filename: null,
      partners: null,
      updateTime: null,
      clicks: null,
      pageIntSuccess: null,
      apk: null,
      language: 'en',
      modList: [{
          title: "WhatsApp Plus APK",
          url: "/whatsapp-plus-apk",
          img: require('@/assets/waplus.webp'), // replace with actual image path
          alt: "WhatsApp Plus APK V18.20",
          version: "V18.20"
        },
        {
          title: "GB WhatsApp iOS",
          url: "/gbwhatsapp-for-iphone",
          img: require('@/assets/ios.webp'), // replace with actual image path
          alt: "GB WhatsApp ios Version"
        },
        {
          title: "Fouad WhatsApp APK",
          img: require('@/assets/mod-icon-4.webp'), // replace with actual image path
          alt: "Fouad WhatsApp APK v10.24",
          version: "v10.24"
        },
        {
          title: "JT WhatsApp APK",
          img: require('@/assets/mod-icon-5.webp'), // replace with actual image path
          alt: "JT WhatsApp APK v10.2",
          version: "v10.2"
        },
        {
          title: "AN WhatsApp APK",
          img: require('@/assets/mod-icon-6.webp'), // replace with actual image path
          alt: "AN WhatsApp APK v41",
          version: "v41"
        },
        {
          title: "Blue WhatsApp APK",
          img: require('@/assets/mod-icon-7.webp'), // replace with actual image path
          alt: "Blue WhatsApp APK v10.1",
          version: "v10.1"
        },
        {
          title: "OB WhatsApp APK",
          img: require('@/assets/mod-icon-8.webp'), // replace with actual image path
          alt: "OB WhatsApp APK v62",
          version: "v62"
        },
        {
          title: "OG WhatsApp APK",
          img: require('@/assets/mod-icon-9.webp'), // replace with actual image path
          alt: "OG WhatsApp APK V18.20",
          version: "V18.20"
        },
        {
          title: "YO WhatsApp APK",
          img: require('@/assets/mod-icon-10.webp'), // replace with actual image path
          alt: "YO WhatsApp APK v10.10",
          version: "v10.10"
        }
      ]

    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'host',
      "downloadicon"
    ]),
  },
  created() {
    this.initParams();
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    download(apk) {
      this.$global.download(apk);
    },
    goback() {
      // this.$router.push('/');
      this.$router.go(-1);
    },
    initParams() {
      this.from = this.$global.GetQueryString('from');
      this.filename = this.$global.GetQueryString('filename');
    },
    fetchData() {
      if (this.from) {
        this.getAPK(true, this.from, this.filename);
      } else {
        this.getAPK();
        this.getOfficialApk();
      }
    },
    updateAPK() {
      this.$emit('update-apk', this.apk);
    },
    getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
      this.$server
        .getAPPInfo({
          promotionChannel: from,
          promotionName: filename
        })
        .then((res) => {
          if (res.code === 200) {
            this.partners = res.data.partners;
            this.updateTime = res.data.updateTime;
            this.clicks = res.data.clicks;
            this.pageIntSuccess = true;
            if (isFromQuery) {
              this.apk = res.data.apk;
              this.updateAPK();
            }
          }
        })
        .catch((err) => {
          console.error('Error fetching APK:', err);
        });
    },
    getOfficialApk() {
      this.$server
        .getOfficialApk({
          domain: 'gbpro.download',
          appName: 'GB_gbpro.download'
        })
        .then((res) => {
          if (res.code === 200) {
            this.apk = res.data.apk;
            this.updateAPK();
            console.log('APK fetched successfully');
          }
        })
        .catch((err) => {
          console.error('Error fetching official APK:', err);
        });
    }
  }
};
</script>
